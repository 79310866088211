import React, { useRef, useEffect, useState } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import "../../../style/dashboard/scenario/AddNewScenario.scss";
import { useForm } from "react-hook-form";
import axios from "axios";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

function AddNewScenario() {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  const TOKEN = process.env.REACT_APP_TOKEN;
  const [releases, setRelease] = useState([]);

  const fetchData = async () => {
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    await sleep(1000);

    const response = await axios({
      method: "GET",
      url: `${API_URL}/api/oil_spill/release/list/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${TOKEN}`,
      },
    });
    console.log(response);
    const json = await response.data;
    // console.log(json);
    setRelease(json);

    console.log("This is Response from GET Release request: ", json);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const windMagRef = useRef();
  const windDirRef = useRef();
  const windDurRef = useRef();

  const [windData, setwindData] = useState([]);
  const windDataG = [];

  const currentMagRef = useRef();
  const currentDirRef = useRef();
  const currentDurRef = useRef();

  const [currentData, setcurrentData] = useState([]);
  const currentDataG = [];

  const updateWindData = () => {
    setwindData([
      ...windData,
      {
        id: windData.length + 1,
        wind_magnitude: windMagRef.current.value,
        wind_direction: windDirRef.current.value,
        wind_duration: windDurRef.current.value,
      },
    ]);
  };
  const windGridItems = windData.map((item, key) =>
    // {id:key,wind_magnitude: item.wind_magnitude}
    windDataG.push({
      id: key,
      wind_magnitude: item.wind_magnitude,
      wind_direction: item.wind_direction,
      wind_duration: item.wind_duration,
    })
  );

  const updateCurrentData = () => {
    setcurrentData([
      ...currentData,
      {
        id: currentData.length + 1,
        current_magnitude: currentMagRef.current.value,
        current_direction: currentDirRef.current.value,
        current_duration: currentDurRef.current.value,
      },
    ]);
  };
  const currentGridItems = windData.map((item, key) =>
    // {id:key,wind_magnitude: item.wind_magnitude}
    windDataG.push({
      id: key,
      wind_magnitude: item.wind_magnitude,
      wind_direction: item.wind_direction,
      wind_duration: item.wind_duration,
    })
  );

  console.log("This is the orginal array", windDataG);
  console.log(windDataG);
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "wind_magnitude",
      headerName: "Wind Magnitude",
      type: "number",
      width: 150,
    },
    {
      field: "wind_direction",
      type: "number",
      headerName: "Wind Direction",
      width: 150,
    },
    { field: "wind_duration", headerName: "Wind Duration", width: 150 },
  ];

  const current_columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "current_magnitude",
      headerName: "Current Magnitude",
      type: "number",
      width: 150,
    },
    {
      field: "current_direction",
      type: "number",
      headerName: "Current Direction",
      width: 150,
    },
    { field: "current_duration", headerName: "Current Duration", width: 150 },
  ];

  const actionColumns = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: () => {
        return (
          <div className="cellAction">
            <div className="editButton"> Edit</div>
            <div className="deleteButton"> Delete</div>
          </div>
        );
      },
    },
  ];

  // const rows = [
  //   { id: 1, wind_magnitude: "Snow", wind_direction: "Jon", wind_duration: 35 },
  //   {
  //     id: 2,
  //     wind_magnitude: "Lannister",
  //     wind_direction: "Cersei",
  //     wind_duration: 42,
  //   },
  //   {
  //     id: 3,
  //     wind_magnitude: "Lannister",
  //     wind_direction: "Jaime",
  //     wind_duration: 45,
  //   },
  // ];

  const rows = () => {};

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);

    axios({
      method: "POST",
      url: `${API_URL}/api/oil_spill/scenario/create/`,
      data: {
        release: data["release"],
        name: data["scenarioName"],
        description: data["releaseDescrption"],
        wind_data: windData,
        current_data: currentData,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${TOKEN}`,
      },
    });
  };

  return (
    <div className="addNewRelease">
      <Sidebar />

      <div className="addNewReleaseContiner">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formInput">
            <label>Select Release</label>
            <select
              {...register("release")}
              type="list"
              placeholder="All Releases"
            >
              <option key="---" value="---">
                --------
              </option>
              {releases.map((release) => (
                <option key={release.id} value={release.id}>
                  {release.name}
                </option>
              ))}
            </select>
          </div>

          <div className="formInput">
            <label>Scenario Name</label>
            <input
              {...register("scenarioName")}
              type="text"
              placeholder="Release Name"
            ></input>
          </div>

          <div className="formInput">
            <label>Descrptions</label>
            <textarea
              {...register("releaseDescrption")}
              type="text"
              placeholder="Descrptions"
            ></textarea>
          </div>
          <input type="submit" />
          <div>
            <h1>Metrological Condition</h1>
            <div>
              <div>
                <label>Wind Magnitude (Knot)</label>
                <input
                  {...register("wind_magnitude")}
                  type="text"
                  placeholder="Wind Magnitude (Knot)"
                  ref={windMagRef}
                ></input>
              </div>
              <div>
                <label>Wind Direction (Degree)</label>
                <input
                  {...register("wind_direction")}
                  type="text"
                  placeholder="Wind Direction (Degree)"
                  ref={windDirRef}
                ></input>
              </div>
              <div>
                <label>Wind Duration (Hours)</label>
                <input
                  {...register("wind_duration")}
                  type="text"
                  placeholder="Wind Duration (Hours)"
                  ref={windDurRef}
                ></input>
              </div>
            </div>

            <div>
              <div>
                <label>Current Magnitude (Knot)</label>
                <input
                  {...register("current_magnitude")}
                  type="text"
                  placeholder="Current Magnitude (Knot)"
                  ref={currentMagRef}
                ></input>
              </div>
              <div>
                <label>Current Direction (Degree)</label>
                <input
                  {...register("current_direction")}
                  type="text"
                  placeholder="Current Direction (Degree)"
                  ref={currentDirRef}
                ></input>
              </div>
              <div>
                <label>Current Duration (Hours)</label>
                <input
                  {...register("current_duration")}
                  type="text"
                  placeholder="Current Duration (Hours)"
                  ref={currentDurRef}
                ></input>
              </div>
            </div>
          </div>
        </form>
        <div>
          <button onClick={updateWindData}> Add Wind Data</button>
        </div>
        <div>
          <button onClick={updateCurrentData}> Add Current Data</button>
        </div>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={windData}
            columns={columns.concat(actionColumns)}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            // getRowId="wind_magnitude"
          />
        </div>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={currentData}
            columns={current_columns.concat(actionColumns)}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            // getRowId="wind_magnitude"
          />
        </div>
        {/* <button onClick={sendData}>Send</button>
         */}
      </div>
    </div>
  );
}

export default AddNewScenario;
