import React, { useRef, useEffect, useState } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import "../../../style/dashboard/scenario/DeleteScenario.scss";
import { useForm } from "react-hook-form";
import axios from "axios";

function DeleteSolve() {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  const TOKEN = process.env.REACT_APP_TOKEN;
  const [releases, setReleases] = useState([]);
  const selectedReleaseID = useRef();
  const inputRelease = useRef();
  const inputReleaseName = useRef();
  const inputReleaseDate = useRef();
  const inputReleaseDescription = useRef();

  const fetchData = async () => {
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    await sleep(1000);

    const response = await axios({
      method: "GET",
      url: `${API_URL}/api/oil_spill/scenario/list/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${TOKEN}`,
      },
    });
    console.log(response);
    const json = await response.data;
    // console.log(json);
    setReleases(json);

    console.log("This is Response from GET request: ", json);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("New Data", data);
    console.log("My ID", selectedReleaseID);
    axios({
      method: "DELETE",
      url: `${API_URL}/api/oil_spill/scenario/${selectedReleaseID.current}/delete/`,
      data: {
        name: inputReleaseName.current.value,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${TOKEN}`,
      },
    });

    // fetchData();
    fetchData();
  };
  const onChangeHandler = (e) => {
    selectedReleaseID.current = e.target.value;
    if (selectedReleaseID.current != "---") {
      console.log("This is release", selectedReleaseID.current);

      inputRelease.current = releases.filter(
        (release) => release.id == selectedReleaseID.current
      )[0];

      console.log("This is release", inputRelease.current);

      inputReleaseName.current.value = inputRelease.current.name;
      inputReleaseDate.current.value = inputRelease.current.release_date;
      inputReleaseDescription.current.value = inputRelease.current.description;

      console.log("This is input name", inputReleaseName.current.value);
    }
  };

  return (
    <div className="updateRelease">
      <Sidebar />

      <div className="updateReleaseContiner">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formInput">
            <label>Select Release to update</label>
            <select
              {...register("releases")}
              type="list"
              placeholder="All Releases"
              onChange={onChangeHandler}
            >
              <option key="---" value="---">
                --------
              </option>
              {releases.map((release) => (
                <option key={release.id} value={release.id}>
                  {release.name}
                </option>
              ))}
            </select>
          </div>
          <div className="formInput">
            <label>Release Name</label>
            <input
              {...register("releaseName")}
              type="text"
              placeholder="Release Name"
              // value={inputReleaseName.current}
              ref={inputReleaseName}
              disabled="disabled"
            ></input>
          </div>
          <div className="formInput">
            <label>Release Date</label>
            <input
              {...register("releaseDate")}
              type="date"
              ref={inputReleaseDate}
              disabled="disabled"
            ></input>
          </div>
          <div className="formInput">
            <label>Descrptions</label>
            <textarea
              {...register("releaseDescription")}
              type="textarea"
              placeholder="Descrptions"
              ref={inputReleaseDescription}
              disabled="disabled"
              rows="4"
              cols="50"
            ></textarea>
          </div>
          <input type="submit" />
        </form>
        {/* <button onClick={sendData}>Send</button>
         */}
      </div>
    </div>
  );
}

export default DeleteSolve;
