import React from "react";
import Sidebaritem from "./SidebarItem";
import items from "./data/sidebar.json";
import "../../style/sidebar/Sidebar.scss";

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebarItems">
        {items.map((item) => (
          <Sidebaritem key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
