import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Tablesx from "../../components/dashboard/Tablesx";
import Tablesx2 from "../../components/dashboard/Tablesx2";
import "../../style/dashboard/Dashboard.scss";
import DataTable from "../../components/datatable/DataTable";

function Dashboard() {
  return (
    <div className="dashboard">
      <div className="contanier">
        <div className="left">
          <div className="siderbar">
            <Sidebar />
          </div>
        </div>
        <div className="right">
          <Tablesx />
          <h1>Dashboard</h1>

          <DataTable />
        </div>
        <div className="result">
          {/* <button onClick={<Release />}>Releases</button> */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
