import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Dashboard from "./pages/dashboard/Dashboard";
import About from "./pages/about/About";

import Home from "./pages/home/Home";
import ShowAllRelease from "./pages/dashboard/release/ShowAllRelease";
import AddNewRelease from "./pages/dashboard/release/AddNewRelease";
import UpdateRelease from "./pages/dashboard/release/UpdateRelease";
import DeleteRelease from "./pages/dashboard/release/DeleteRelease";

import ShowAllProject from "./pages/dashboard/project/ShowAllProject";
import AddNewProject from "./pages/dashboard/project/AddNewProject";
import UpdateProject from "./pages/dashboard/project/UpdateProject";
import DeleteProject from "./pages/dashboard/project/DeleteProject";

import ShowAllScenario from "./pages/dashboard/scenario/ShowAllScenario";
import AddNewScenario from "./pages/dashboard/scenario/AddNewScenario";
import UpdateScenario from "./pages/dashboard/scenario/UpdateScenario";
import DeleteScenario from "./pages/dashboard/scenario/DeleteScenario";

import ShowAllSolve from "./pages/dashboard/solve/ShowAllSolve";
import AddNewSolve from "./pages/dashboard/solve/AddNewSolve";
import UpdateSolve from "./pages/dashboard/solve/UpdateSolve";
import DeleteSolve from "./pages/dashboard/solve/DeleteSolve";

function App() {
  return (
    <div className="app">
      <div className="appHeader">
        <Header />
      </div>
      <div className="appContanier">
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="dashboard">
              <Route index element={<Dashboard />} />

              <Route
                path="release/showallrelease"
                element={<ShowAllRelease />}
              />
              <Route path="release/addnewrelease" element={<AddNewRelease />} />
              <Route path="release/updaterelease" element={<UpdateRelease />} />
              <Route path="release/deleterelease" element={<DeleteRelease />} />

              <Route
                path="project/showallproject"
                element={<ShowAllProject />}
              />
              <Route path="project/addnewproject" element={<AddNewProject />} />
              <Route path="project/updateproject" element={<UpdateProject />} />
              <Route path="project/deleteproject" element={<DeleteProject />} />

              <Route
                path="scenario/showallscenario"
                element={<ShowAllScenario />}
              />
              <Route
                path="scenario/addnewscenario"
                element={<AddNewScenario />}
              />
              <Route
                path="scenario/updatescenario"
                element={<UpdateScenario />}
              />
              <Route
                path="scenario/deletescenario"
                element={<DeleteScenario />}
              />

              <Route path="solve/showallsolve" element={<ShowAllSolve />} />
              <Route path="solve/addnewsolve" element={<AddNewSolve />} />
              <Route path="solve/updatesolve" element={<UpdateSolve />} />
              <Route path="solve/deletesolve" element={<DeleteSolve />} />
            </Route>

            <Route path="/about" element={<About />} />
          </Route>
        </Routes>
      </div>
      <div className="appFooter">
        <Footer />
      </div>
    </div>
  );
}

export default App;

// useEffect(() => {
//   const fetchData = async () => {
//     const data = await fetch(`${API_URL}/mosram_api/oils/`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         mode: "no-cors",
//       },
//     });

//     const json = await data.json();
//     console.log(json);
//     setOils(json);
//     console.log(process.env.REACT_APP_API_ENDPOINT);
//     return json;
//   };
