import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import "../../../style/dashboard/scenario/ShowAllScenario.scss";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

function ShowAllSolves() {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  const TOKEN = process.env.REACT_APP_TOKEN;
  // console.log(API_URL);
  // console.log(TOKEN);

  const [project, setProject] = useState([]);
  const [rowsData, setRowData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${API_URL}/api/oil_spill/solve/list/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${TOKEN}`,
        },
      });

      const json = await data.json();
      console.log(json);
      setProject(json);
    };

    fetchData();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 300 },
    { field: "scenario", headerName: "Name", width: 300 },
    { field: "time_step", headerName: "Time Step", width: 300 },
    { field: "tracking_type", headerName: "Tracking Type", width: 300 },

    {
      field: "created_at",
      headerName: "Date Created",
      type: "date",
      width: 250,
    },
    {
      field: "modified_at",
      headerName: "Date Modified",
      description: "This column has a value getter and is not sortable.",
      width: 250,
    },
  ];

  const rowso = [
    { id: 1, lastName: "Snow", title: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", title: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", title: "Jaime", age: 45 },
  ];

  // setRowData(releases);

  console.log("my relases", project);
  // console.log("RowData", rowsData);
  return (
    <div className="showAllRelease">
      <Sidebar />

      <div className="showAllReleaseContiner">
        Test again
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={project}
            columns={columns}
            pageSize={6}
            rowsPerPageOptions={[6]}
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );
}

export default ShowAllSolves;
