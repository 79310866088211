import React from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import "../../../style/dashboard/project/AddNewProject.scss";
import { useForm } from "react-hook-form";
import axios from "axios";

function AddNewProject() {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  const TOKEN = process.env.REACT_APP_TOKEN;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);

    axios({
      method: "POST",
      url: `${API_URL}/api/oil_spill/project/create/`,
      data: {
        name: data["projectName"],
        description: data["projectDescrption"],
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${TOKEN}`,
      },
    });
  };

  return (
    <div className="addNewRelease">
      <Sidebar />

      <div className="addNewReleaseContiner">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formInput">
            <label>Project Name</label>
            <input
              {...register("projectName")}
              type="text"
              placeholder="Project Name"
            ></input>
          </div>

          <div className="formInput">
            <label>Descrptions</label>
            <textarea
              {...register("projectDescrption")}
              type="text"
              placeholder="Descrptions"
            ></textarea>
          </div>
          <input type="submit" />
        </form>
        {/* <button onClick={sendData}>Send</button>
         */}
      </div>
    </div>
  );
}

export default AddNewProject;
