import axios from "axios";
import React, { useState, useEffect } from "react";
import "../../style/home/Home.scss";

function Home() {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  console.log(API_URL);
  const [oils, setOils] = useState([]);

  const fetchOilData = () => {
    const options = {
      method: "GET",
      // url: "http://127.0.0.1:8000/mosram_api/oils/",
      url: { API_URL } + "mosram_api/oils/",
    };

    axios
      .request(options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  // console.log(fetchOilData());

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${API_URL}/mosram_api/oils/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await data.json();
      console.log(json);
      setOils(json);
      console.log(process.env.REACT_APP_API_ENDPOINT);
      return json;
    };

    // call the function
    const result = fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  return (
    <div className="home">
      Home
      <div> Oil Name</div>
      <div> Oil Descrption</div>
      <div>
        {oils.map((oil) => {
          return <h2 key={oil.id}>{oil.name}</h2>;
        })}
      </div>
    </div>
  );
}

export default Home;
