import React, { useRef, useEffect, useState } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import "../../../style/dashboard/solve/AddNewSolve.scss";
import { useForm } from "react-hook-form";
import axios from "axios";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayerGroup,
  LayersControl,
} from "react-leaflet";
import iconUrl from "../../../style/svg/Simple_red_circl.png";
import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import { CSVLink } from "react-csv";

// import "../../../style/leaflet//leaflet.scss";
export const newicon = new Leaflet.Icon({
  iconUrl,
  // iconAnchor: [5, 55],
  // popupAnchor: [10, -44],
  shadowSize: [50, 64],
  shadowAnchor: [4, 62],
  iconSize: [3, 3],
});
console.log(iconUrl);
function AddNewSolve() {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  const TOKEN = process.env.REACT_APP_TOKEN;
  const [scenarios, setScenarios] = useState([]);
  const [results, setResults] = useState([]);

  const fetchData = async () => {
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    await sleep(1000);

    const response = await axios({
      method: "GET",
      url: `${API_URL}/api/oil_spill/scenario/list/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${TOKEN}`,
      },
    });
    console.log(response);
    const json = await response.data;
    // console.log(json);
    setScenarios(json);

    console.log("This is Response from GET Scenario request: ", json);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // console.log(data);
    const response = await axios({
      method: "POST",
      url: `${API_URL}/api/oil_spill/solve/create/`,
      data: {
        scenario: data["scenario"],
        name: data["solveName"],
        time_step: data["solveTime_step"],
        tracking_type: data["solveTracking_type"],
      },
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/json",
        // "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        Authorization: `Token ${TOKEN}`,
      },
    });

    // console.log(response.data);

    const response_result = await axios({
      method: "GET",
      url: `${API_URL}/api/oil_spill/result/list/params/?solve_id=${response.data["id"]}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${TOKEN}`,
      },
    });
    // console.log(response_result.data);
    setResults(response_result.data);
  };
  console.log(results);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "step", headerName: "Step", width: 100 },
    { field: "time_step", headerName: "Time", width: 100 },
    { field: "longitude", headerName: "Longitude", width: 100 },
    { field: "latitude", headerName: "Latitude", width: 100 },

    {
      field: "location",
      headerName: "location",
      type: "date",
      width: 250,
    },
  ];

  const rowso = [
    { id: 1, lastName: "Snow", title: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", title: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", title: "Jaime", age: 45 },
  ];

  // setRowData(releases);

  return (
    <div className="addNewSolve">
      <div className="Up">
        <Sidebar />

        <div className="addNewSolveContiner">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formInput">
              <label>Select Scenario</label>
              <select
                {...register("scenario")}
                type="list"
                placeholder="All Scenarios"
              >
                <option key="---" value="---">
                  --------
                </option>
                {scenarios.map((scenario) => (
                  <option key={scenario.id} value={scenario.id}>
                    {scenario.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="formInput">
              <label>Name</label>
              <input
                {...register("solveName")}
                type="text"
                placeholder="Solve Name"
              ></input>
            </div>
            <div className="formInput">
              <label>Time step</label>
              <input
                {...register("solveTime_step")}
                type="text"
                placeholder="Time step"
              ></input>
            </div>
            <div className="formInput">
              <label>Tracking</label>
              <input
                {...register("solveTracking_type")}
                type="radio"
                value="Tracking"
              ></input>
              <label>Back Tracking</label>
              <input
                {...register("solveTracking_type")}
                type="radio"
                value="Back Tracking"
              ></input>
            </div>
            <input type="submit" />
          </form>
          {/* Export Button Start */}
          <CSVLink
            className="downloadbtn"
            filename="my-file.csv"
            data={results}
          >
            Export to CSV
          </CSVLink>
          {/* Export Button End */}
          <div className="addNewSolveContiner">
            <div style={{ height: "400px", width: "100%" }}>
              <DataGrid
                rows={results}
                columns={columns}
                pageSize={6}
                rowsPerPageOptions={[6]}
                checkboxSelection
              />
            </div>
          </div>
        </div>
      </div>
      <div className="map">
        <MapContainer
          center={[31, 29]}
          zoom={4}
          scrollWheelZoom={true}
          style={{ width: "98%", height: "400px" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* add google map tile url  */}
          <TileLayer
            attribution="Google Maps"
            url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
          />

          {results.map((result) => (
            <Marker
              na
              icon={newicon}
              position={[result.latitude, result.longitude]}
            >
              <Popup>
                Latitude: {result.latitude} <br />
                Longitude: {result.Longitude}
                <br />
                Step: {result.step}
                <br />
                Time Step: {result.time_step}
                <br />
              </Popup>
            </Marker>
          ))}

          <LayersControl>
            <LayersControl.BaseLayer name="Open Street Map">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Mapbox Map">
              <TileLayer
                attribution='&copy; <a href="https://www.mapbox.com">Mapbox</a> '
                url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}"
                accessToken={"your token here"}
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Mapbox Map Satellite">
              <TileLayer
                attribution='&copy; <a href="https://www.mapbox.com">Mapbox</a> '
                url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}"
                accessToken={"your token here"}
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer checked name="Google Map">
              <TileLayer
                attribution="Google Maps"
                url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Map Satellite">
              <LayerGroup>
                <TileLayer
                  attribution="Google Maps Satellite"
                  url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                />
                <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
              </LayerGroup>
            </LayersControl.BaseLayer>
          </LayersControl>
        </MapContainer>
      </div>
    </div>
  );
}

export default AddNewSolve;
