import React from "react";
import { Link } from "react-router-dom";
import "../../style/header/Header.scss";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";

function Header() {
  return (
    <div className="header">
      <div className="headerLeft">
        <img src="" alt="" />
        <Link to="/" className="logo">
          Mosram.net
        </Link>
      </div>
      <div className="headerRight">
        <div className="items">
          <div className="item">
            <DarkModeOutlinedIcon className="icon" />
          </div>

          <div className="item">
            <Link to="/" className="link">
              Home
            </Link>
          </div>
          <div className="item">
            <Link to="/about" className="link">
              About
            </Link>
          </div>
          <div className="item">
            <Link to="/countactUs" className="link">
              Contact us
            </Link>
          </div>

          <div className="item">
            <LanguageOutlinedIcon className="icon" />
            <p>English</p>
          </div>

          <div className="item">
            <DashboardIcon className="icon" />
            <Link to="/dashboard" className="link">
              <p>Dashboard</p>
            </Link>
          </div>

          <div className="item">
            <img src="../images/avatar-16x9_1600.jpg" className="avatar" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
