import React, { useRef, useEffect, useState } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import "../../../style/dashboard/release/AddNewRelease.scss";
import { useForm } from "react-hook-form";
import axios from "axios";

function AddNewRelease() {
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  const TOKEN = process.env.REACT_APP_TOKEN;

  const [projects, setProjects] = useState([]);

  const fetchData = async () => {
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    await sleep(1000);

    const response = await axios({
      method: "GET",
      url: `${API_URL}/api/oil_spill/project/list/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${TOKEN}`,
      },
    });
    console.log(response);
    const json = await response.data;
    // console.log(json);
    setProjects(json);

    console.log("This is Response from GET Project request: ", json);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);

    axios({
      method: "POST",
      url: `${API_URL}/api/oil_spill/release/create/`,
      data: {
        project: data["project"],
        name: data["releaseName"],
        release_date: data["releaseDate"],
        description: data["releaseDescrption"],
        volume: data["releaseVolume"],
        latitude: data["releaseLat"],
        longitude: data["releaseLong"],
        account: TOKEN,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${TOKEN}`,
      },
    });

    console.log(data);
  };

  return (
    <div className="addNewRelease">
      <Sidebar />

      <div className="addNewReleaseContiner">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formInput">
            <label>Select Project</label>
            <select
              {...register("project")}
              type="list"
              placeholder="All Scenarios"
            >
              <option key="---" value="---">
                --------
              </option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>

          <div className="formInput">
            <label>Release Name</label>
            <input
              {...register("releaseName")}
              type="text"
              placeholder="Release Name"
            ></input>
          </div>
          <div className="formInput">
            <label>Release Name</label>
            <input
              {...register("releaseDate")}
              type="date"
              placeholder="Release Date"
            ></input>
          </div>
          <div className="formInput">
            <label>Descrptions</label>
            <textarea
              {...register("releaseDescrption")}
              type="text"
              placeholder="Descrptions"
            ></textarea>
          </div>

          <div className="formInput">
            <label>Volume</label>
            <input
              {...register("releaseVolume")}
              type="number"
              min="1"
              placeholder="Volume"
            ></input>
          </div>

          <div className="formInput">
            <label>Latitude</label>
            <input
              {...register("releaseLat")}
              type="text"
              placeholder="Release Latitude"
            ></input>
          </div>

          <div className="formInput">
            <label>Longitude</label>
            <input
              {...register("releaseLong")}
              type="text"
              placeholder="Release Longitude"
            ></input>
          </div>

          <input type="submit" />
        </form>
        {/* <button onClick={sendData}>Send</button>
         */}
      </div>
    </div>
  );
}

export default AddNewRelease;
